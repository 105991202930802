import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import { config } from "./config";

const socket = io.connect(config.api.url, {
    path: "/log/socket/",
    cookie: false,
    transports: ["websocket"],
    forceNew: true
});

ReactDOM.render(<SocketProvider socket={socket}><I18nextProvider i18n={ i18n }><App /></I18nextProvider></SocketProvider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
