import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {socketConnect} from 'socket.io-react';
import axios from 'axios';
import queryString from 'query-string';
import { config, route } from "../config";

class LostPassword extends Component {

    constructor(props) {
        super(props);

        const values = queryString.parse(this.props.location.search);

        this.state = {
            password: '',
            passwordError: false,
            token: values.token,
            email: values.email
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    handleChangePassword(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleSubmit(event) {
        let message = {
            email: this.state.email,
            token: this.state.token,
            password: this.state.password
        };
        if (this.state.password.length > 0) {
            axios.post(config.api.url + "/api/users/reset-password/activation", message)
                .then( response => {
                    window.location = config.basename + route.pass_changed;
                })
                .catch( error => {
                    console.error(error);
                    const errMsg = error.response && error.response.data ? error.response.data.error.toLowerCase() : "general_error";
                    this.setState({
                        passwordError: true,
                        passwordErrorText: `new_password.errors.${errMsg || "general_error"}`
                    });
                });
        } else {
            this.setState({
                passwordError: true,
                passwordErrorText: 'new_password.password_error'
            });
        }
    }

    render() {
        const {t} = this.props;

        return (
            <div className="form-container container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="form-box">
                            <h4 className="h-title">{t('new_password.header')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('new_password.instructions')}} />
                            <input type="password" className="custom-input" name="password" required="required" autoComplete="off" maxLength="256"  value={this.state.password} onChange={this.handleChangePassword} placeholder={t('new_password.password')}  />
                            <button type="submit" className="btn btn-normal" onClick={this.handleSubmit}>{t('new_password.save_button')}</button>
                        </div>
                        {this.state.passwordError && <div className="form-validation fail"><div>{t(this.state.passwordErrorText)}</div></div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default socketConnect(withTranslation()(LostPassword));
