import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    login_box: {
                        during_login: "Please wait...",
                        error: "Something went wrong. Try again.",
                        no_correct_data: "Incorrect username or password.",
                        inactive_user: "Your account is inactive.",
                        logged_out: "Logged out of the application due to the exceeded session duration."
                    },
                    password_login: {
                        header: "Login with password",
                        header_short: "Login",
                        instructions: "If you are <span class=\"underline\">not using</span> Edward mobile app login here:",
                        username: "Username",
                        password: "Password",
                        wait: "Wait...",
                        login: "LOGIN",
                        or: "or",
                        register: "Check out demo",
                        register_user: "https://edward.ai/en/sign-up-for-demo/",
                        remind_password: "Remind me password"
                    },
                    form: {
                        email: {
                            error: "Enter a valid email address.",
                            general_error: "An error occurred during resetting password. Please contact with our support.",
                            user_no_exist: "This email address is not exists in our database.",
                            http_error: "I can't connect with server. Please try again."
                        }
                    },
                    password_reset: {
                        header: "Forgot your password?",
                        instructions: "Enter your email address and we'll send you a password reset link!",
                        email: "Email",
                        reset_pass: "RESET PASSWORD"
                    },
                    new_password: {
                        header: "Create new password",
                        instructions: "Enter your new password below. After that you can login into dashboard.",
                        password: "password",
                        save_button: "SAVE PASSWORD",
                        password_error: "Please provide your new password",
                        errors: {
                            general_error: "An error occurred during setting password. Please contact with our support.",
                            server_error: "We have problem with updating. Please contact with our support.",
                            no_reset_request: "To your email address has not been sent a password reset request.",
                            reset_link_expired: "Your activation link expired. Please repeat process of resetting password.",
                            invalid_token: "Provided token not match. Please contact with our support.",
                            user_no_exist: "Provided user email not exist!",
                            invalid_email: "Provided user email is not correct!"
                        }
                    },
                    pass_changed: {
                        header: "Password changed",
                        instructions: "Your new password is changed! Now you can click on below button to login page",
                        login: "LOGIN"
                    },
                    email_sent: {
                        header: "Message sent",
                        instructions: "We sent you an activation link to your registered email.",
                    },
                    qr_login: {
                        header: "Quick login",
                        instructions: "Open Edward mobile app and scan this code:",
                        error: {
                            general: "Can't log in, please refresh page and try again.",
                            device_time: "Please make sure you have a good time set on your phone and try again after setting the correct time.",
                            user_not_found: "I can't find your account. If you have just installed application please try again in few minutes.",
                            inactive_user: "Your account is inactive.",
                            wrong_scanner: "You are using the wrong scanning app. Please use Edward mobile app instead.",
                            session_not_found: "Session not found."
                        },
                        help: {
                            instructions: "Open Edward mobile app on your smartphone and use this icon to scan the code:",
                            open: "?",
                            close: "OK"
                        }
                    }
                }
            },
            pl: {
                translations: {
                    login_box: {
                        during_login: "Trwa logowanie...",
                        error: "Coś poszło nie tak. Spróbuj jeszcze raz.",
                        no_correct_data: "Login lub hasło jest niepoprawne.",
                        inactive_user: "Twoje konto jest nieaktywne.",
                        logged_out: "Wylogowano z aplikacji ze względu na przekroczony czas trwania sesji."
                    },
                    password_login: {
                        header: "Logowanie z użyciem hasła",
                        header_short: "Logowanie",
                        instructions: "Jeżeli <span class=\"underline\">nie korzystasz</span> z aplikacji mobilnej zaloguj się tutaj:",
                        username: "Nazwa użytkownika",
                        password: "Hasło",
                        wait: "Poczekaj...",
                        login: "ZALOGUJ SIĘ",
                        or: "lub",
                        register: "Sprawdź demo",
                        register_user: "https://edward.ai/pl/zobacz-demo-edwarda/",
                        remind_password: "Przypomnij hasło"
                    },
                    form: {
                        email: {
                            error: "Proszę wpisać poprawny adres email.",
                            general_error: "Wystąpił błąd podczas resetowania hasła. Proszę, skontaktuj się z naszym działem wsparcia.",
                            user_no_exist: "Podany adres email nie istnieje w naszej bazie.",
                            http_error: "Nie mogę połączyć się z serwerem - spróbuj ponownie."
                        }
                    },
                    password_reset: {
                        header: "Nie pamiętasz hasła?",
                        instructions: "Wpisz swój email, a my wyślemy Ci link do ustawienia nowego.",
                        email: "Email",
                        reset_pass: "RESETUJ HASŁO"
                    },
                    new_password: {
                        header: "Stwórz nowe hasło",
                        instructions: "Wpisz nowe hasło poniżej a następnie będziesz mógł/mogła zalogować sie do panelu.",
                        password: "hasło",
                        save_button: "ZAPISZ HASŁO",
                        password_error: "Proszę wprowadzić nowe hasło",
                        errors: {
                            general_error: "Wystąpił błąd podczas ustawiania hasła. Proszę, skontaktuj się z naszym działem wsparcia.",
                            server_error: "Nie mogę zaktualizować danych. Proszę, skontaktuj się z naszym działem wsparcia.",
                            no_reset_request: "Na podany adres email nie została wysłana prośba o reset hasła.",
                            reset_link_expired: "Twój link wygasł. Proszę powtórzyć proces resetowania hasła.",
                            invalid_token: "Wprowadzony token nie jest poprawny. Proszę, skontaktuj się z naszym działem wsparcia.",
                            user_no_exist: "Wprowadzony adres email nie istnieje!",
                            invalid_email: "Wprowadzony adres email nie jest poprawny!"
                        }
                    },
                    pass_changed: {
                        header: "Hasło zmienione",
                        instructions: "Twoje hasło zostało zmienione. Teraz możesz kliknąć w poniższy przycisk i zalogować się.",
                        login: "ZALOGUJ SIĘ"
                    },
                    email_sent: {
                        header: "Wiadomość wysłana",
                        instructions: "Na Twój adres email wysłaliśmy Ci link do zmiany hasła.",
                    },
                    qr_login: {
                        header: "Szybkie logowanie",
                        instructions: "Włącz aplikację mobilną Edward i zeskanuj ten kod:",
                        error: {
                            general: "Nie mogę sie zalogować, proszę odśwież stronę i spróbuj ponownie.",
                            device_time: "Sprawdź proszę, czy masz dobrze ustawiony czas na telefonie i spróbuj ponownie po ustawieniu prawidłowego czasu.",
                            user_not_found: "Nie mogę odnaleźć Twojego konta, jeśli instalowałeś aplikację przed chwilą spróbuj proszę za kilka minut.",
                            inactive_user: "Twoje konto jest nieaktywne.",
                            wrong_scanner: "Użyłeś błędnej aplikacji do skanowania kodu, użyj proszę aplikacji mobilnej Edward.",
                            session_not_found: "Nie odnaleziono sesji."
                        },
                        help: {
                            instructions: "Otwórz aplikację Edward na swoim telefonie, dotknij ikony w prawym dolnym rogu ekranu i włącz skaner:",
                            open: "?",
                            close: "OK"
                        }
                    }
                }
            }
        },
        fallbackLng: 'en',

        // have a common namespace used around the full app
        ns: ['translations'],
        nsSeparator: '|',
        defaultNS: 'translations',

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;