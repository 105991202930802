import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {socketConnect} from 'socket.io-react';
import axios from 'axios';
import { config, route } from "../config";
import { validateEmail } from "../helpers";

class LostPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            emailError: false,
            emailErrorText: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    handleChangeEmail(event) {
        this.setState({
            email: event.target.value
        })
    }

    handleSubmit() {
        let emailValidation = validateEmail(this.state.email);
        if (emailValidation) {
            let message = {
                email: this.state.email,
                lang: this.props.i18n.language.substr(0, 2)
            };
            axios.post(config.api.url + "/api/users/reset-password", message)
                .then( response => {
                    if (response.status === 200) {
                        window.location = config.basename + route.pass_reset_sent;
                    }
                })
                .catch( error => {
                    console.error(error);
                    const errMsg = error.response && error.response.data ? error.response.data.error.toLowerCase() : "general_error";
                    this.setState({
                        emailError: true,
                        emailErrorText: `form.email.${errMsg || "general_error"}`
                    });
                });
        } else {
            this.setState({
                emailError: true,
                emailErrorText: 'form.email.error'
            });
        }
    }

    render() {

        const {t} = this.props;

        return (
            <div className="form-container container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="form-box">
                            <h4 className="h-title">{t('password_reset.header')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('password_reset.instructions')}} />
                            <input type="email" className="custom-input" name="email" required="required" autoComplete="off" maxLength="256" value={this.state.email} onChange={this.handleChangeEmail} placeholder={t('password_reset.email')}  />
                            <button type="submit" className="btn btn-normal" onClick={this.handleSubmit}>{t('password_reset.reset_pass')}</button>
                        </div>
                        {this.state.emailError && <div className="form-validation fail"><div>{t(this.state.emailErrorText)}</div></div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default socketConnect(withTranslation()(LostPassword));
