import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {socketConnect} from 'socket.io-react';
import axios from 'axios';
import qr_code_scanner from '../assets/img/qr_code_scanner.png';
import QRCode from 'qrcode.react';
import {config, route} from "../config";
import { uuid } from "../helpers";

class QrLogin extends Component {
    constructor() {
        super();

        let qrSession = uuid();

        this.state = {
            helpEnabled: false,
            qrSession: qrSession,
            qrUrl: this.setQrUrl(qrSession)
        };

        this.toggleHelp = this.toggleHelp.bind(this);
        this.handleQrAuth = this.handleQrAuth.bind(this);
    }

    setQrUrl(sessionId) {
        const domain = window.location.hostname;
        return `https://${domain}/log/qr/${sessionId}/_1_/_2_/_3_`
    }

    handleQrAuth(code) {
        let url = config.api.url + "/log/qr/";
        let params = "s=" + this.state.qrSession + "&c=" + code;

        let options = {
            url: url,
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: params,
            timeout: 3000
        };

        axios(options)
            .then( response => {
                if (response.status === 200) {
                    this.props.showError("");
                    this.props.toggleDone();
                    window.location = route.dashboard;
                }
            })
            .catch( error => {
                let response = error.response;
                if (response && response.status === 403) {
                    this.props.showError(this.props.t('qr_login.error.inactive_user'));
                    console.error("Inactive user | Status: " + response.status);
                } else {
                    console.error("HTTP error code: " + error.response.status + " , responseText: " + error.response.responseText);
                    this.props.showError(this.props.t('qr_login.error.general'));
                }
            });
    }

    toggleHelp(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState(prevState => ({
            helpEnabled: !prevState.helpEnabled
        }));
        this.props.showError("");
    }

    componentDidMount() {
        this.props.socket.on("qrAuthOk", function (code) {
            this.handleQrAuth(code);
        }.bind(this));
        this.props.socket.on("showError", function (message) {
            if (message === 'qr_login.error.wrong_scanner') {
                if (!this.state.helpEnabled) {
                    this.toggleHelp(null);
                }
            }
            this.props.showError(this.props.t(message));
        }.bind(this));
        this.props.socket.on('connect', function() {
            console.log("Connected socket to the server");
            this.props.socket.emit("session", this.state.qrSession);
        }.bind(this));
        this.props.socket.on('reconnect', function() {
            console.log("Reconnected socket to the server");
            this.props.socket.emit("session", this.state.qrSession);
        }.bind(this));
    }

    render() {
        const {t} = this.props;

        return (
            <div className="qrcolumn">
                <div className="helperpopup" style={{display: this.state.helpEnabled ? 'block' : 'none'}}>
                    <p className="instruction">{t('qr_login.help.instructions')}</p>
                    <img src={qr_code_scanner} width="100" alt="QR Code Scanner"/>
                    <button className="btn btn-ok" data-gatrack="button,helper" onClick={this.toggleHelp}>{t('qr_login.help.close')}</button>
                </div>
                <h4 className="h-title qr-login-title">{t('qr_login.header')}</h4>
                <button className="helperbutton" onClick={this.toggleHelp}>{t('qr_login.help.open')}</button>
                <p>{t('qr_login.instructions')}</p>
                <div className="qrcode-img"><QRCode size={200} value={this.state.qrUrl}/></div>
            </div>
        );
    }
}

export default socketConnect(withTranslation()(QrLogin));
