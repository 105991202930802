import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import { config, route } from '../config';
import {Link} from 'react-router-dom';
import axios from 'axios'

class PasswordLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: false
        };

        this.handleErrorMessage = this.handleErrorMessage.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleErrorMessage() {
        if (this.state.error) {
            this.props.showError();
            this.setState({error: false});
        }
    }

    handleChangeUsername(event) {
        this.handleErrorMessage();
        this.setState({username: event.target.value});
    }

    handleChangePassword(event) {
        this.handleErrorMessage();
        this.setState({password: event.target.value});
    }

    handleSubmit(event) {
        let url = config.api.url + "/log/in/";
        let params = "username=" + this.state.username + "&password=" + encodeURIComponent(this.state.password);

        let options = {
            url: url,
            method: "POST",
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            data: params,
            timeout: 3000
        };

        axios(options)
            .then( response => {
                if (response.status === 200) {
                    this.props.toggleDone();
                    if (response.headers["x-redirect-user"]) {
                        window.location = response.headers["x-redirect-user"];
                    } else {
                        window.location = route.dashboard;
                    }
                }
            })
            .catch( error => {
                let response = error.response;
                if (response && response.status === 401) {
                    if (!this.state.error) {
                        this.props.showError(this.props.t('login_box.no_correct_data'));
                        this.setState({error: true});
                    }
                    console.error("Incorrect login or password | Status: " + response.status);
                } else if (response && response.status === 403) {
                    if (!this.state.error) {
                        this.props.showError(this.props.t('login_box.inactive_user'));
                        this.setState({error: true});
                    }
                    console.error("Inactive user | Status: " + response.status);
                } else {
                    if (!this.state.error) {
                        this.props.showError(this.props.t('login_box.error'));
                        this.setState({error: true});
                    }
                    console.error("HTTP error code: " + response.status);
                }
            });

        event.preventDefault();
    }

    render() {
        const {t} = this.props;

        return (
            <form action="#" method="post" onSubmit={this.handleSubmit}>
                <h4 className="h-title default-login-title d-none d-lg-block ">{t('password_login.header')}</h4>
                <h4 className="h-title default-login-title sm d-block d-lg-none">{t('password_login.header_short')}</h4>
                <p className="d-none d-lg-block" dangerouslySetInnerHTML={{__html: t('password_login.instructions')}}/>
                <div className="login-box">
                    <input type="text" className="custom-input" name="username" required="required"  value={this.state.username} maxLength="256" placeholder={t('password_login.username')} onChange={this.handleChangeUsername} />
                    <input type="password" className="custom-input" name="password" required="required" autoComplete="off" maxLength="256" value={this.state.password} placeholder={t('password_login.password')} onChange={this.handleChangePassword} />
                    <button type="submit" className="btn btn-login" data-gatrack="button,login_button">{t('password_login.login')}</button>
                    {/*<span className="or">{t('password_login.or')}</span>*/}
                    {/*<a href={t('password_login.register_user')} className="btn btn-register" data-gatrack="button,register_button" >{t('password_login.register')}</a>*/}
                    <Link to={route.lost_pass} className="remind-password">{t('password_login.remind_password')}</Link>
                </div>
            </form>
        );
    }
}

export default withTranslation()(PasswordLogin);
