import React, {Component} from 'react';
import LoginBox from "./components/LoginBox";
import LostPassword from "./components/LostPassword";
import ResetPassword from "./components/ResetPassword";
import {socketConnect} from 'socket.io-react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { config, route } from './config';
import logo from './assets/img/logo.png';
import { getParameterByName } from "./helpers";

import './assets/css/normalize.css';
import './assets/css/app.css';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const ref = getParameterByName('ref');
        if (ref) {
            let exDate = new Date();
            exDate.setDate(exDate.getDate() + 365);
            if (ref.match(/^[a-zA-Z0-9.;:\-_@]+$/g)) {
                window.Cookies.set('ref', ref, { expires: 365 , path: '/', secure: true });
            }
        }
    }

    render() {
        const {t} = this.props;

        return (
            <Router basename={config.basename}>
                <div id="main">
                    <div className="main-container container">
                        <div className="container logo-container">
                            <Link to={route.homepage} className="logo" ><img src={logo} width="200" alt="Logo" /></Link>
                        </div>
                        <Switch>
                            <Route path={route.pass_reset_sent}>
                                <div className="form-container container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 offset-md-3">
                                            <div className="form-box">
                                                <h4 className="h-title">{t('email_sent.header')}</h4>
                                                <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{__html: t('email_sent.instructions')}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Route>
                            <Route path={route.pass_changed}>
                                <div className="form-container container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 offset-md-3">
                                            <div className="form-box">
                                                <h4 className="h-title">{t('pass_changed.header')}</h4>
                                                <p dangerouslySetInnerHTML={{__html: t('pass_changed.instructions')}} />
                                                <Link to={route.homepage} className="btn btn-normal">{t('pass_changed.login')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Route>
                            <Route path={route.lost_pass} component={LostPassword} />
                            <Route path={route.pass_reset} component={ResetPassword} />
                            <Route path={route.homepage}>
                                <LoginBox />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router>
        )
    }
}

export default socketConnect(withTranslation()(App));
