import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { socketConnect } from 'socket.io-react';
import PasswordLogin from './PasswordLogin';
import QrLogin from './QrLogin';
import { getParameterByName } from "../helpers";

class LoginBox extends Component {
    constructor(props) {
        super(props);

        let showError = "";

        const isLoggedOut = getParameterByName('logged_out');
        if (isLoggedOut === "true") {
            showError = this.props.t('login_box.logged_out');
        }

        this.state = {
            showError: showError,
            showDone: false
        };
        this.showError = this.showError.bind(this);
        this.toggleDone = this.toggleDone.bind(this);
    }

    showError(error) {
        this.setState({
            showError: error
        });
    }

    toggleDone() {
        this.setState(prevState => ({
            showDone: !prevState.showDone
        }));
    }

    render() {

    const { t } = this.props;

        return (
            <div className="form-container container">
                <div className="form-box">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 login-default">
                            <PasswordLogin showError={this.showError} toggleDone={this.toggleDone} />
                        </div>
                        <div className="col-sm-6 d-none d-lg-block login-qrcode">
                            <QrLogin showError={this.showError} toggleDone={this.toggleDone} />
                        </div>
                    </div>
                </div>
                {this.state.showDone && <div className="form-validation done"><div>{t('login_box.during_login')}</div></div>}
                {this.state.showError && <div className="form-validation fail"><div>{this.state.showError}</div></div>}
            </div>
        );
  }
}

export default socketConnect(withTranslation()(LoginBox));
