// Route
export const route = {
    pass_changed: "/password/changed",
    pass_reset_sent: "/password/sent",
    lost_pass: "/password/lost",
    pass_reset: "/password/reset",
    homepage: "/",
    dashboard: "/dashboard/?l=fst",
    admin: "/admin/",
    register_user: "/register/user/"
};

const dev = {
    basename: "/login",
    api: {
        url: "https://dev.edward.ai",
    }
};

const prod = {
    basename: "/login",
    api: {
        url: 'https://' + window.location.host
    },
};

export const config = {
    // Add common config values here
    ...process.env.NODE_ENV === 'production' ? prod : dev
};
